import { pushToDataLayer } from './pushToDataLayer'

export function useHorizontalScrollTracking(metadata) {
  const trackOnScrollRef = React.useRef(null)

  return { onTouchMove: handleTouchMove, onTouchEnd: handleTouchStart }

  function handleTouchStart() {
    if (trackOnScrollRef.current) {
      pushToDataLayer({ event: 'interaction_stop', metadata })
      trackOnScrollRef.current = false
    }
  }

  function handleTouchMove(e) {
    if (!trackOnScrollRef.current) {
      const postion = e.currentTarget.scrollLeft

      if (postion > 0) {
        pushToDataLayer({ event: 'interaction_start', metadata })
        trackOnScrollRef.current = true
      }
    }
  }
}
