import { ContainerMd, ContainerLg } from '/features/buildingBlocks/Container'
import { AnchorMenu }  from '/features/job/pageOnly/AnchorMenu'
import { GreenhouseDescription } from '/features/job/pageOnly/GreenhouseDescription'
import { Benefits } from '/features/job/pageOnly/Benefits'
import { Procedure } from '/features/pageOnly/Procedure'
import { useClosestHash } from './useClosestHash'
import { Recruiter } from '/features/pageOnly/Recruiter'
import { Application } from '/features/job/pageOnly/Application'
import { ApplicationFormApp } from '/features/job/applicationForm/ApplicationFormApp'
import { useAreQuestionsRenderable } from '/features/job/applicationForm/useAreQuestionsRenderable'
import { MeetTheTeam } from '/features/pageOnly/MeetTheTeam'
import { useSpring, config } from '@react-spring/web'
import { useScrollProgression, triggers }  from '@kaliber/scroll-progression'
import { JobOpenings } from '/features/pageOnly/JobOpenings'
import { useTranslate } from '/machinery/I18n'
import { AboutBannerLight } from '/features/pageOnly/AboutBanner'
import { Icon } from '/features/buildingBlocks/Icon'
import { QuoteWithImage } from '/features/pageOnly/QuoteWithImage'
import { TeamTestimonial, TeamTestimonialDark } from '/features/pageOnly/TeamTestimonial'
import lineTeam from '/images/lines/line-meet-the-team-white.raw.svg'

import styles from './ScrollContent.css'

export function ScrollContent({ job, jobAdditionalData, language, relevantJobs, jobPath, referrer }) {
  const allQuestionsRenderable = useAreQuestionsRenderable(job.id, job.questions)
  const { department, highlightedBenefits, recruiter, whoWeAre, meetTheTeamDefault } = jobAdditionalData ?? {}
  const { quoteWithImage, testimonials, showTestimonials } = department ?? {}
  const sections = useSections({ job, department, highlightedBenefits, recruiter, meetTheTeamDefault })
  const hashes = React.useMemo(() => Object.values(sections).map(x => x.id), [sections])
  const { activeHash, refs } = useClosestHash({ hashes })
  const { __ } = useTranslate()

  const [style, spring] = useSpring(() => ({
    scaleX: 0,
    config: config.molasses
  }))

  const bodyRef = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.top() },
    end: { element: triggers.bottom(), scrollParent: triggers.bottom() },
    onChange(progression) {
      spring.start({ scaleX: progression })
    }
  })

  return (
    <div className={styles.component} ref={bodyRef}>
      {!isEmptyObject(sections) && (
        <AnchorMenu
          layoutClassName={styles.menu}
          jobTitle={job.title}
          applyUrl='#form'
          {...{ sections, activeHash, style, jobPath }}
        />
      )}

      {sections.description && (
        <section
          data-x='description'
          id={sections.description.id}
          ref={refs[sections.description.id]}
          className={cx(styles.description, styles.scrollMargin)}
        >
          <ContainerMd>
            <GreenhouseDescription content={job.content} />
          </ContainerMd>
        </section>
      )}

      {showTestimonials
        ? (
          Boolean(testimonials?.testimonials?.length) && (
            <section data-x='team-testimonials' className={styles.testimonials}>
              {testimonials.color === 'black'
                ? <TeamTestimonialDark testimonials={testimonials.testimonials} />
                : <TeamTestimonial testimonials={testimonials.testimonials} />
              }
            </section>
          )
        )
        : (
          quoteWithImage && (
            <section data-x='quote-with-image' className={styles.quoteWithImage}>
              <QuoteWithImage
                quote={quoteWithImage.quote}
                jobRole={quoteWithImage.role}
                author={quoteWithImage.author}
                image={quoteWithImage.image}
                color={quoteWithImage.color}
              />
            </section>
          )
        )
      }

      {sections.benefits && (
        <section
          data-x='benefits'
          id={sections.benefits.id}
          ref={refs[sections.benefits.id]}
          className={cx(styles.benefits, styles.scrollMargin)}
        >
          <ContainerMd>
            <Benefits
              benefits={highlightedBenefits.benefits}
              title={highlightedBenefits.title}
              description={highlightedBenefits.content}
            />
          </ContainerMd>
        </section>
      )}
      {whoWeAre && (
        <AboutBannerLight
          title={whoWeAre.title}
          button={whoWeAre.button}
        />
      )}
      {sections.team &&
        <section
          data-x='meet-the-team'
          id={sections.team.id}
          ref={refs[sections.team.id]}
          className={cx(styles.scrollMargin, styles.meetTheTeam)}
        >
          <Icon icon={lineTeam} layoutClassName={styles.shapeTeam} />
          <MeetTheTeam
            title={__`meet-the-team-title`}
            subtitle={job.department?.name}
            description={department?.meetTheTeam?.description || meetTheTeamDefault?.description}
            button={department?.meetTheTeam?.button || meetTheTeamDefault?.button}
            items={department?.meetTheTeam?.images || meetTheTeamDefault.images}
          />
        </section>
      }

      {sections.recruiter && (
        <section
          data-x='recruiter'
          id={sections.recruiter.id}
          ref={refs[sections.recruiter.id]}
          className={cx(styles.scrollMargin, styles.recruiter)}
        >
          <ContainerMd>
            <Recruiter
              image={recruiter.image}
              name={recruiter.title}
              email={recruiter.email}
              linkedin={recruiter.linkedin}
            />
          </ContainerMd>
        </section>
      )}

      <section
        data-x='form'
        id='form'
        className={cx(styles.scrollMargin, styles.form)}
      >
        <Application
          title={__`job-apply-title`}
          description={__`job-apply-description`}
          image={jobAdditionalData?.formBackgroundImage?.formBackgroundImage}
        >
          {allQuestionsRenderable
            ? (
              <ApplicationFormApp
                jobId={job.id}
                questions={job.questions}
                jobTitle={job.title}
                clientIdQuestionName={job.clientIdQuestionName}
                {...{ language, referrer }}
              />
            )
            : <FormFallback />
          }
        </Application>
      </section>
      {sections.procedure && (
        <section
          data-x='procedure'
          id='procedure'
          ref={refs[sections.procedure.id]}
          className={cx(styles.scrollMargin, styles.procedure)}
        >
          <Procedure />
        </section>
      )}

      {Boolean(relevantJobs.length) && (
        <section className={styles.jobOpenings} data-x='job-openings'>
          <ContainerLg>
            <JobOpenings
              title={__`jobs-related-title`}
              jobs={relevantJobs}
            />
          </ContainerLg>
        </section>
      )}
    </div>
  )
}

function useSections({ job, highlightedBenefits, department, recruiter, meetTheTeamDefault }) {
  const { __ } = useTranslate()

  return {
    ...(job.content && {
      description: {
        label: __`job-anchor-description`,
        id: 'description'
      }
    }),
    ...(Boolean(highlightedBenefits?.benefits?.length) && {
      benefits: {
        label: __`job-anchor-benefits`,
        id: 'benefits'
      }
    }),
    ...((Boolean(department?.meetTheTeam?.images?.length) || Boolean(meetTheTeamDefault?.images?.length)) && {
      team: {
        label: __`job-anchor-team`,
        id: 'team'
      }
    }),
    ...(recruiter && {
      recruiter: {
        label: __`job-anchor-recruiter`,
        id: 'recruiter'
      }
    }),
    procedure: {
      label: __`job-anchor-process`,
      id: 'procedure'
    }
  }
}

function FormFallback() {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentFormFallback}>
      <>
        {__`application-form-fallback-message`}
        <a href='mailto:recruitment@bitvavo.com'>recruitment@bitvavo.com</a>
      </>
    </div>
  )
}

function isEmptyObject(object) {
  if (typeof object !== 'object') throw Error('Not an object')
  return Object.keys(object).length === 0
}
