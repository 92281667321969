import styles from './GreenhouseDescription.css'

export function GreenhouseDescription({ content }) {
  return (
    <div
      className={styles.component}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}
