export async function getClientId(ga4Id) {
  return new Promise((resolve, reject) => {
    getGaClientId(ga4Id).then(resolve).catch(reject)
    timeout(1000).catch(reject)
  })
}

async function getGaClientId(ga4Id) {
  return new Promise((resolve) => {
    gtag('get', ga4Id, 'client_id', resolve)
  })
}

async function timeout(milliseconds) {
  return new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout obtaining clientId')), milliseconds))
}
