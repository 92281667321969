import { useFormField, useBooleanFormField } from '@kaliber/forms'
import { useTranslate } from '/machinery/I18n'
import { Icon } from '/features/buildingBlocks/Icon'
import { useElementSize } from '@kaliber/use-element-size'
import  { animated, useSpring } from '@react-spring/web'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'

import triangleDown from '/images/icons/triangle-down.raw.svg'
import paperclip from '/images/icons/paperclip.raw.svg'
import closeIcon from '/images/icons/close.raw.svg'
import errorIcon from '/images/icons/error.raw.svg'

import styles from './FormField.css'

export const formFieldRenderers = {
  short_text: FormFieldTextInput,
  long_text: FormFieldTextArea,
  boolean: FormFieldOptionGroup,
  single_select: FormFieldDropdown,
  multi_select: FormFieldCheckboxGroup,
  attachment: FormFieldFile,
}

export function FormFieldTextInput({ type, field, label, required, placeholder = undefined }) {
  const { name, state, eventHandlers } = useFormField(field)
  const { value, showError, hasFocus, error } = state

  return (
    <FormFieldBase className={styles.componentTextInput} error={showError && error}>
      {label && (
        <label
          className={cx(styles.textLabel, (hasFocus || value) && styles.active)}
          htmlFor={name}
        >
          {label}{required && '*'}
        </label>
      )}
      <input
        className={cx(styles.textInput, showError && styles.hasError)}
        id={name}
        {...{ name, type, placeholder, required, value }}
        {...eventHandlers}
      />
      {showError && <div className={styles.errorIcon}><Icon icon={errorIcon} /></div>}
    </FormFieldBase>
  )
}

export function FormFieldDropdown({ field, label = undefined, options, required }) {
  const { name, state, eventHandlers } = useFormField(field)
  const { value, showError, error } = state
  const { __ } = useTranslate()

  return (
    <FormFieldBase className={styles.componentDropdown} error={showError && error}>
      {label && (
        <label
          className={styles.dropdownLabel}
          htmlFor={name}
        >
          {label}{required && '*'}
        </label>
      )}
      <div className={styles.dropdownInner}>
        <select
          className={cx(styles.dropdown, showError && styles.hasError)}
          id={name}
          {...eventHandlers}
          {...{ name, required, value }}
        >
          <option value='' disabled>{__`field-default-option-value`}</option>
          {options.map(({ label, value }) =>
            <option key={value} {...{ value }}>
              {label}
            </option>
          )}
        </select>
        <div className={styles.dropdownIcon}>
          <Icon icon={triangleDown} />
        </div>
      </div>
    </FormFieldBase>
  )
}

export function FormFieldFile({ label, field,  required, accept }) {
  const { name, state, eventHandlers: { onChange, ...eventHandlers } } = useFormField(field)
  const { value, showError, error } = state
  const { __ } = useTranslate()

  return (
    <FormFieldBase className={styles.componentFile} error={showError && error}>
      {value
        ? (
          <div className={styles.fileInputLabel}>
            {value.name}
            <button className={styles.fileClose} type='button' onClick={_ => onChange(null)}>
              <Icon icon={closeIcon} />
            </button>
          </div>
        ) : (
          <div className={styles.fileInputLayout}>
            <input
              type='file'
              id={name}
              className={styles.fileInput}
              {...{ accept, name }}
              {...eventHandlers}
              onChange={e => {
                onChange(e.currentTarget.files[0])
                eventHandlers.onBlur(e)
                trackFieldBlur(field)
              }}
            />
            <label className={styles.fileInputLabel} htmlFor={name}>
              {__`field-upload-your`}{` ${label}`}{required && '*'}
              <Icon layoutClassName={styles.fileInputIcon} icon={paperclip} />
            </label>
          </div>
        )}
    </FormFieldBase>
  )
}

export function FormFieldTextArea({ field, label, required = undefined, placeholder = undefined }) {
  const { name, state, eventHandlers } = useFormField(field)
  const { value, showError, error } = state

  return (
    <FormFieldBase className={styles.componentTextArea} error={showError && error}>
      {label && (
        <label className={styles.textAreaLabel} htmlFor={name}>
          {label}{required && '*'}
        </label>
      )}
      <textarea
        className={cx(styles.textarea, showError && styles.hasError)}
        id={name}
        {...eventHandlers}
        {...{ name, placeholder, required, value }}
      />
    </FormFieldBase>
  )
}

export function FormFieldOptionGroup({ field, label, options, required }) {
  const { name, state, eventHandlers } = useFormField(field)
  const { value, showError, error } = state

  return (
    <FieldGroup error={showError && error} {...{ label, required }}>
      {options.map(option => {
        const id = `${name}_${option.label}`
        return (
          <div key={id} className={styles.radioItem}>
            <input
              className={styles.radioInput}
              type='radio'
              checked={value === option.value.toString()}
              value={option.value}
              {...{ name, id }}
              {...eventHandlers}
            />
            <label className={styles.radioLabel} htmlFor={id}>
              {option.label}
            </label>
          </div>
        )
      })}
    </FieldGroup>
  )
}

export function FormFieldCheckboxGroup({ field, label, options, required }) {
  const { name, state, eventHandlers: { onChange, ...eventHandlers } } = useFormField(field)
  const { value, showError, error } = state

  return (
    <FieldGroup error={showError && error} {...{ label, required }}>
      {options.map(option => {
        const id = `${name}_${option.label}`
        return (
          <div key={id} className={styles.checkboxInput}>
            <input
              className={styles.checkbox}
              type='checkbox'
              checked={Array.isArray(value) && value.includes(option.value)}
              value={option.value}
              onChange={handleChangeFor(option.value)}
              {...{ name, id }}
              {...eventHandlers}
            />
            <label className={styles.checkboxLabel} htmlFor={id}>
              {option.label}
            </label>
          </div>
        )
      })}
    </FieldGroup>
  )

  function handleChangeFor(changedValue) {
    return e => {
      const newValue =
        !Array.isArray(value)
          ? [changedValue]
          : value.includes(changedValue)
            ? value.filter(x => x !== changedValue)
            : value.concat(changedValue)
      onChange(newValue)
    }
  }
}

export function FormFieldConsentCheckbox({ field, children, required }) {
  const { name, state, eventHandlers } = useBooleanFormField(field)
  const { value, showError, error } = state

  return (
    <FormFieldBase
      className={styles.componentConsentCheckbox}
      error={showError && error}
      {...{ field, required }}
    >
      <div className={styles.checkboxItemWrapper}>
        <div className={cx(styles.checkboxInput, showError && styles.hasError)}>
          <input
            className={styles.checkbox}
            id={name}
            type='checkbox'
            {...eventHandlers}
            {...{ name, required, value }}
          />
          {children && (
            <label className={styles.checkboxLabel} htmlFor={name}>
              {children}
            </label>
          )}
        </div>
      </div>
    </FormFieldBase>
  )
}

function FormFieldBase({ className, children, error = undefined }) {
  const { ref: sizeRef, size: { height } } = useElementSize()
  const animatedError = useSpring({
    height: `${error ? (height + 18) : 0}px`,
    opacity: error ? 1 : 0,
    transform: `${error ? 'translateY(-3)' : 'translateY(-14px)'}`,
  })

  return (
    <div className={cx(styles.componentBase, className)}>
      {children}
      <animated.div className={styles.notification} style={animatedError}>
        <div className={styles.notificationInner} ref={sizeRef}>
          {error && <Error {...{ error }} />}
        </div>
      </animated.div>
    </div>
  )
}

function FieldGroup({ label, children, error, required }) {
  return (
    <FormFieldBase className={styles.componentFieldGroup} {...{ error }}>
      <legend className={styles.formFieldLegend}>{label}{required && '*'}</legend>
      <fieldset className={styles.fieldset}>
        {children}
      </fieldset>
    </FormFieldBase>
  )
}

function Error({ error }) {
  const { __ } = useTranslate()

  const errorMessages = {
    email: _ => __`field-validation-email`,
    checked: _ => __`field-validation-checked`,
    required: _ => __`field-validation-required`,
    phone: _ => __`field-validation-phone`,
    fileSize: x => __({ fileSize: bytesToMb(x) })`field-validation-fileSize`,
    fileExtension: extensions => {
      return __({ fileExtensions: readable(extensions) })`field-validation-fileExtension`

      function readable(array) {
        const [last, ...rest] = array.slice().reverse()
        return `${rest.reverse().join(', ')} or ${last}`
      }
    },
  }

  return errorMessages[error.id](...error.params)
}

function bytesToMb(bytes) {
  return bytes / (1024 * 1024)
}

function trackFieldBlur(field) {
  const fieldState = field.state.get()
  pushToDataLayer({
    event: `application_form_field_${fieldState.invalid ? 'failed' : 'completed'}`,
    metadata: { form: { field: field.name } }
  })
}
