const SCROLL_OFFSET = 120

export function useClosestHash({ hashes }) {
  const [activeHash, setActiveHash] = React.useState(null)
  const refs = useHashRefs(hashes)

  React.useEffect(
    () => {
      window.addEventListener('scroll', updateActiveHash)
      return () => window.removeEventListener('scroll', updateActiveHash)

      function updateActiveHash() {
        const newActiveHash = getClosestHash(refs)
        if (newActiveHash === activeHash) return

        setActiveHash(newActiveHash)
        const base = window.location.pathname + window.location.search
        const currentHash = window.location.hash.slice(1)
        if (newActiveHash === currentHash || !newActiveHash)
          return

        // can not set `location.hash` because that would cause actual navigation
        window.history.replaceState(
          {},
          document.title,
          `${base}#${newActiveHash || ''}`
        )
      }
    },
    [activeHash, refs]
  )

  return { activeHash, refs }
}

function useHashRefs(hashes) {
  const refsRef = React.useRef({})

  hashes.forEach(hash => { if (!(hash in refsRef.current)) refsRef.current[hash] = React.createRef() })
  Object.keys(refsRef.current).forEach(hash => { if (!hashes.includes(hash)) delete refsRef.current[hash] })

  return refsRef.current
}

function getClosestHash(refs) {
  const [closest] = Array
    .from(Object.entries(refs)) // [['hash', ref], ['hash2', ref2], ...]
    .filter(([hash, ref]) => Boolean(ref.current))
    .map(([hash, ref]) => ({
      hash,
      rect: ref.current.getBoundingClientRect()
    }))
    .sort(sortByDistanceAscending)

  return closest?.hash ?? null
}

function sortByDistanceAscending(a, b) {
  const aMin = Math.min(Math.abs(a.rect.top), Math.abs(a.rect.bottom) + SCROLL_OFFSET)
  const bMin = Math.min(Math.abs(b.rect.top), Math.abs(b.rect.bottom) + SCROLL_OFFSET)
  return aMin - bMin
}
