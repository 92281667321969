import { error } from '@kaliber/forms/validation'

export function fileSize(maxSize) {
  return x => x && (x.size === 0 || x.size > maxSize) && error('fileSize', maxSize)
}

export function fileExtension(extensions) {
  return x => {
    if (!x) return

    const [ext] = x.name.split('.').reverse()
    return (!ext || !extensions.some(x => ext.toLowerCase() === x)) &&
      error('fileExtension', extensions)
  }
}

const phoneRegex = /^[0-9)(-+]+$/
export const phone = x => x && !phoneRegex.test(x) && error('phone')

export function checked(x) {
  return !x && error('checked')
}
