import { ButtonLinkBlue } from '/features/buildingBlocks/Button'
import { Icon } from '/features/buildingBlocks/Icon'
import { animated } from '@react-spring/web'
import { useTranslate } from '/machinery/I18n'
import { useHorizontalScrollTracking } from '/machinery/tracking/useHorizontalScrollTracking'
import { Link } from '/features/buildingBlocks/Link'
import { useClientConfig } from '/machinery/ClientConfig'

import triangleDown from '/images/icons/triangle-down.raw.svg'
import share from '/images/icons/share/share.raw.svg'
import linkedin from '/images/icons/share/linkedin.raw.svg'
import twitter from '/images/icons/share/twitter.raw.svg'
import email from '/images/icons/share/email.raw.svg'
import whatsapp from '/images/icons/share/whatsapp.raw.svg'
import close from '/images/icons/share/close.raw.svg'

import styles from './AnchorMenu.css'

const AnchorMenuLinkFwd = React.forwardRef(AnchorMenuLink)

export function AnchorMenu({ jobTitle, sections, activeHash, layoutClassName, style, applyUrl, jobPath }) {
  const scrollContainerRef = React.useRef(null)
  const activeRef = React.useRef(null)
  const eventListeners = useHorizontalScrollTracking({ interaction: { title: 'anchor-menu', type: 'slided' } })

  // We'd rather scroll immediately, but we're blocked by https://bugs.chromium.org/p/chromium/issues/detail?id=1121151
  useOnScrollFinished(
    () => { scrollContainerRef.current.scrollLeft = activeRef.current.offsetLeft },
    { enabled: Object.values(sections).some(x => x.id === activeHash) }
  )

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.layout}>
        <nav className={styles.navigation} ref={scrollContainerRef} {...eventListeners}>
          {Object.values(sections).map((item) => (
            <AnchorMenuLinkFwd key={item.id} hash={item.id} label={item.label} active={item.id === activeHash} ref={item.id === activeHash ? activeRef : null} />
          ))}
        </nav>
        <Buttons layoutClassName={styles.actionButtons} {...{ applyUrl, jobTitle, jobPath }} />
      </div>
      <Bar layoutClassName={styles.progressBar} {...{ style }} />
    </div>
  )
}

function AnchorMenuLink({ active, label, hash }, ref) {
  return (
    <a href={`#${hash}`}
      className={cx(styles.componentLink, active && styles.active)}
      data-x='scroll-to-section'
      {...{ ref }}
    >
      {label}
      <div className={styles.icon}><Icon icon={triangleDown} /></div>
    </a>
  )
}

function Buttons({ layoutClassName, applyUrl, jobTitle, jobPath }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentButtons, layoutClassName)}>
      <ButtonLinkBlue
        href={applyUrl}
        dataX='scroll-to-form'
        layoutClassName={styles.button}
      >
        {__`apply-button-label`}
      </ButtonLinkBlue>
      <Share layoutClassName={styles.share} {...{ jobTitle, jobPath }} />
    </div>
  )
}

function Bar({ layoutClassName, style }) {
  return (
    <div className={cx(styles.componentBar, layoutClassName)}>
      <animated.div
        className={styles.progress}
        {...{ style }}
      />
    </div>
  )
}

function Share({ layoutClassName, jobTitle, jobPath }) {
  const [expand, setExpand] = React.useState(false)
  const { host } = useClientConfig()
  const url = `${host}${jobPath}`

  const socialLinks = [
    {
      href: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
      icon: linkedin
    },
    {
      href: `https://twitter.com/intent/tweet?url=${url}&text=${jobTitle}`,
      icon: twitter
    },
    {
      href: `https://api.whatsapp.com/send?text=${jobTitle} - ${url}`,
      icon: whatsapp
    },
    {
      href: `mailto:?subject=${jobTitle}&body=${url}`,
      icon: email
    }
  ]

  return (
    <div className={cx(styles.componentShare, layoutClassName, expand && styles.expand)}>
      {!expand && (
        <button
          onClick={() => setExpand(true)}
          className={cx(styles.expandShareButton, expand && styles.hide)}
        >
          <Icon icon={share} />
        </button>
      )}
      <div className={cx(styles.socialsWrapper)}>
        <div className={cx(styles.socialsInner)}>
          {socialLinks.map((link, index) => (
            <Link
              key={index}
              href={link.href}
              dataX='link-to-share'
              layoutClassName={styles.socialsIcon}
            >
              <Icon icon={link.icon} />
            </Link>
          ))}
          <button className={styles.socialsCloseButton} onClick={() => setExpand(false)}>
            <Icon icon={close} />
          </button>
        </div>
      </div>
    </div>
  )
}

function useOnScrollFinished(onScrollFinish, options) {
  const { enabled } = options
  const callback = useEvent(onScrollFinish)

  React.useEffect(
    () => {
      if (!enabled) return

      let timeout

      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }

      function handleScroll() {
        clearTimeout(timeout)
        timeout = setTimeout(callback, 100)
      }
    },
    [enabled, callback]
  )
}

function useEvent(fn) {
  const fnRef = React.useRef(null)
  fnRef.current = fn

  return React.useCallback((...args) => fnRef.current(...args), [])
}
