import { HeadingMd } from '/features/buildingBlocks/Heading'
import { ButtonLinkBlue } from '/features/buildingBlocks/Button'
import { Image } from '/features/buildingBlocks/Image'
import { PortableTextSimple } from '/features/buildingBlocks/PortableText'
import { useTranslate } from '/machinery/I18n'

import styles from './Benefits.css'

export function Benefits({ benefits, title, description }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.component}>
      {title && (<HeadingMd h='2' {...{ title }} layoutClassName={styles.heading} />)}
      {description && <PortableTextSimple layoutClassName={styles.description} value={description} />}
      <ul className={styles.benefits}>
        {Boolean(benefits?.length) && benefits.map(({ title, iconImage, alt }, _id) => (
          <li className={cx(styles.benefit)} key={_id}>
            <div className={styles.coverImage}>
              <Image
                image={{ ...iconImage, ...(alt && { alt }) }}
                layoutClassName={styles.image}
              />
            </div>
            <span className={styles.label}>
              {title}
            </span>
          </li>
        ))}
      </ul>
      <div className={styles.buttons}>
        <ButtonLinkBlue
          href='#form'
          dataX='scroll-to-form'
          layoutClassName={styles.button}
        >
          {__`apply-button-label`}
        </ButtonLinkBlue>
      </div>
    </div>
  )
}
