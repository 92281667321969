import { PortableText as PortableTextReact } from '@portabletext/react'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { LinkBlue } from '/features/buildingBlocks/Link'
import { routeMap } from '/routeMap'

import styles from './PortableText.css'

export function PortableText({ layoutClassName = undefined, value }) {
  return (
    <PortableTextBase
      className={cx(styles.component, layoutClassName)}
      {...{ value, components }}
    />
  )
}

export function PortableTextSimple({ layoutClassName = undefined, value }) {
  return (
    <PortableTextBase
      className={cx(styles.componentSimple, layoutClassName)}
      components={componentsSimple}
      {...{ value }}
    />
  )
}

export function PortableTextSimpleCleanHtml({ value }) {
  return (
    <PortableTextReact components={componentsSimpleCleanHtml} {...{ value }} />
  )
}

function PortableTextBase({ value, components, className = undefined }) {
  return (
    <div className={cx(styles.componentBase, className)}>
      <PortableTextReact {...{ value, components }} />
    </div>
  )
}

/** @type {import('@portabletext/react').PortableTextProps['components']} */
export const components = {
  block: {
    normal: ({ children }) => <p className={styles.paragraph}>{children}</p>,
    heading: ({ children }) => <h2 className={styles.heading}>{children}</h2>,
    subheading: ({ children }) =>  <h3 className={styles.subheading}>{children}</h3>,
  },
  list: {
    bullet: ({ children }) => <ul className={cx(styles.list, styles.listUnordered)}>{children}</ul>,
    number: ({ children }) => <ol className={cx(styles.list, styles.listOrdered)}>{children}</ol>,
  },
  listItem: (props) => <li className={styles.listItem}>{props.children}</li>,
  marks: {
    strong: (props) => <strong className={styles.strong}>{props.children}</strong>,
    em: (props) => <em className={styles.em}>{props.children}</em>,
    externalLink: ({ value, children }) => <LinkBlue href={value.href} dataX='link'>{children}</LinkBlue>,
    internalLink: ({ value, children }) => value.ref
      ? <LinkBlue href={determineDocumentPathSync({ document: value.ref, routeMap })} dataX='link'>{children}</LinkBlue>
      : <>{children}</>,
  },
}

// If information changes in this block, also modify 'componentsSimpleCleanHtml'
/** @type {import('@portabletext/react').PortableTextProps['components']} */
export const componentsSimple = {
  block: {
    normal: ({ children }) => <p className={styles.paragraph}>{children}</p>,
  },
  marks: {
    strong: (props) => <strong className={styles.strong}>{props.children}</strong>,
    em: (props) => <em className={styles.em}>{props.children}</em>,
    externalLink: ({ value, children }) => <LinkBlue href={value.href} dataX='link'>{children}</LinkBlue>,
    internalLink: ({ value, children }) => value.ref
      ? <LinkBlue href={determineDocumentPathSync({ document: value.ref, routeMap })} dataX='link'>{children}</LinkBlue>
      : <>{children}</>,
  },
}

/** @type {import('@portabletext/react').PortableTextProps['components']} */
export const componentsSimpleCleanHtml = {
  block: {
    normal: ({ children }) => <p>{children}</p>,
  },
  marks: {
    strong: (props) => <strong>{props.children}</strong>,
    em: (props) => <em>{props.children}</em>,
    externalLink: ({ value, children }) => <a href={value.href}>{children}</a>,
    internalLink: ({ value, children }) => value.ref
      ? <a href={determineDocumentPathSync({ document: value.ref, routeMap })}>{children}</a>
      : <>{children}</>,
  },
}
