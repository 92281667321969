import { formFieldRenderers } from '/features/job/applicationForm/FormField'
import { useReportError } from '/machinery/ReportError'

export function useAreQuestionsRenderable(jobId, questions) {
  const reportError = useReportError()

  return React.useMemo(
    () => {
      const problems = questions.filter(x => !formFieldRenderers[x.type])
      if (problems.length) reportError(new Error(`Job ${jobId} - No renderers found  for ${problems.map(x => x.type).join(', ')}`))
      return !problems.length
    },
    [questions, jobId, reportError]
  )
}
