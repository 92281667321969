import { QueryClient, QueryClientProvider } from 'react-query'
import { ApplicationForm } from './ApplicationForm'
import { FirebaseAppProvider } from '/machinery/FirebaseAppProvider'
import { useClientConfig } from '/machinery/ClientConfig'

const queryClient = new QueryClient()

export function ApplicationFormApp({ jobId, jobTitle, questions, language, clientIdQuestionName, referrer }) {
  const config = useClientConfig()

  return (
    <FirebaseAppProvider config={config.firebase} name={config.firebase.appName}>
      <QueryClientProvider client={queryClient}>
        <ApplicationForm {...{ jobId, jobTitle, questions, language, clientIdQuestionName, referrer }} />
      </QueryClientProvider>
    </FirebaseAppProvider>
  )
}
