import { HeadingLg } from '/features/buildingBlocks/Heading'
import { ButtonLinkBlue } from '/features/buildingBlocks/Button'
import { Icon } from '/features/buildingBlocks/Icon'
import { ChipDepartment, ChipEmployment, ChipLocation } from '/features/buildingBlocks/Chip'
import { Link } from '/features/buildingBlocks/Link'
import { useTranslate, useLanguage } from '/machinery/I18n'
import { makeSlug } from '/machinery/slug'
import { routeMap } from '/routeMap'

import arrowRight from '/images/icons/arrow-right.raw.svg'
import line from '/images/lines/line-job-openings.raw.svg'

import styles from './JobOpenings.css'

export function JobOpenings({ jobs, title, jobsCount = undefined }) {
  const { __ } = useTranslate()
  const language = useLanguage()

  return (
    <div className={styles.component}>
      <Icon icon={line} layoutClassName={styles.lineBottom} />
      <div className={styles.header}>
        <HeadingLg h='2' layoutClassName={styles.heading} {...{ title }} />
        {jobsCount && <span className={styles.jobsCount}>{jobsCount}</span>}
      </div>
      <ul className={styles.jobCards}>
        {jobs.map(job => {
          const [firstOffice] = job._source.offices ?? []

          return (
            <JobCard
              key={job._id}
              title={job._source.title}
              location={firstOffice?.name}
              employment={job._source.employmentType}
              department={job._source.department?.name}
              href={routeMap.app.jobs.detail({
                language,
                jobTitle: makeSlug({ input: job._source.title, language }),
                jobId: job._source.id
              })}
            />
          )
        })}
      </ul>
      <ButtonLinkBlue
        href={routeMap.app.jobs.index({ language })}
        dataX='cta-to-jobs'
        layoutClassName={styles.button}
      >
        {__`see-all-openings`}
      </ButtonLinkBlue>
    </div>
  )
}

function JobCard({ title, location, employment, department, href }) {
  return (
    <div className={styles.componentJobCard}>
      <div className={styles.content}>
        <div className={styles.title}>
          {title}
        </div>
        <div className={styles.chips}>
          {location && <ChipLocation {...{ location }} />}
          {employment && <ChipEmployment {...{ employment }} />}
          {department && <ChipDepartment {...{ department }} />}
        </div>
      </div>
      <Link {...{ href }} dataX='link-to-job' layoutClassName={cx(styles.link, styles.relativeToParent)}>
        <Icon icon={arrowRight} layoutClassName={styles.icon} />
      </Link>
    </div>
  )
}
