import { HeadingMd } from '/features/buildingBlocks/Heading'
import { ButtonLinkBlue } from '/features/buildingBlocks/Button'
import { useTranslate } from '/machinery/I18n'
import { useHorizontalScrollTracking } from '/machinery/tracking/useHorizontalScrollTracking'
import procedure from '/images/procedure.svg'

import styles from './Procedure.css'

export function Procedure() {
  const { __ } = useTranslate()
  const eventListeners = useHorizontalScrollTracking({ interaction: { title: 'procedure', type: 'slided' } })

  return (
    <div className={styles.component} >
      <div className={styles.container} {...eventListeners}>
        <div className={styles.content}>
          <HeadingMd h={2} title={__`job-procedure-title`} layoutClassName={styles.title} />
          <ButtonLinkBlue
            href='#form'
            dataX='scroll-to-form'
            layoutClassName={styles.button}
          >
            {__`job-procedure-form-anchor-label`}
          </ButtonLinkBlue>
        </div>
        <div className={styles.graphWrapper}>
          <img src={procedure} alt='the selection procedure visualised in a graph' />
        </div>
        <small className={styles.disclaimer}>
          {__`job-procedure-disclaimer`}
        </small>
      </div>
    </div>
  )
}
