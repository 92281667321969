import { useMediaQuery } from '@kaliber/use-media-query'
import { HeadingLg } from '/features/buildingBlocks/Heading'
import { ImageCover } from '/features/buildingBlocks/Image'
import mediaStyles from '/cssGlobal/media.css'

import styles from './Application.css'

export function Application({ title, description, children, image = undefined }) {
  const viewportIsMd = useMediaQuery(mediaStyles.viewportMd)

  return (
    <div className={styles.component}>
      <div className={styles.layout}>
        <div className={styles.content}>
          <HeadingLg h='2' {...{ title }} />
          <p className={styles.description}>{description}</p>
        </div>
        <div className={styles.form}>
          {children}
        </div>
      </div>
      {image && (
        <div className={styles.coverImage}>
          <ImageCover aspectRatio={viewportIsMd ? 16 / 9 : 9 / 16} layoutClassName={styles.image} {...{ image }} />
        </div>
      )}
    </div>
  )
}
